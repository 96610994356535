import React, {FC} from "react";
import styles from './node.module.css';

interface NodeHtmlPropsI {
    title: string;
    description: string;
    onClick: () => void;
    bind: any
}

const NodeHtml: FC<NodeHtmlPropsI> = ({title, description, onClick, bind}) => {
    return(
        <div {...bind()} onClick={onClick} className={styles.wrapper}>
            <h1>
                {title}
            </h1>
            <div className={styles.node__body}>
                {description}
            </div>
        </div>
    );
}

export default NodeHtml;