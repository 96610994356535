import styles from './app.module.css';
import git from './images/git.svg';

import {Scene, Controls, CardPreview} from './components';
import {useSelector} from "react-redux";


function App() {

    //const isCardPreviewToggled = useSelector(state => state.activeNodesReducer.activeNodeId !== null);

    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <div className={styles.logo}>
                    Cerebrum
                </div>
                <a href="https://github.com/OlejaTheHuman/cerebrum">
                    <img alt={'git'} src={git} className={styles.navigation}/>
                </a>
            </header>
            <main className={styles.main}>
                <div className={styles.card__preview}>
                  <CardPreview/>
                </div>
                <div className={styles.graph__view}>
                    <Scene/>
                </div>
                <div className={styles.controls}>
                    <Controls/>
                </div>
            </main>
        </div>
    );
}

export default App;
