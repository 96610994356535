const SET_ACTIVE_NODE = "SET_ACTIVE_NODE";

interface defaultStateI {
    activeNodeId: null | number;
}

interface actionI {
    type: typeof SET_ACTIVE_NODE;
    payload: number;
}

const defaultState: defaultStateI = {
    activeNodeId: null
}

export const activeNodesReducer = (state = defaultState, action: actionI): defaultStateI => {
    switch (action.type) {
        case SET_ACTIVE_NODE:
            return {
                activeNodeId: action.payload,
            }
        default:
            return state
    }
}