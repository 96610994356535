import * as React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {FunctionComponent} from "react";

const CardPreview: FunctionComponent = () => {
    const activeNodeId = useSelector((state: RootState) => state.activeNodesReducer.activeNodeId);

    const title = useSelector((state: RootState) => state.nodesReducer.filter(node => node?.id === activeNodeId)[0]?.title);

    const description = useSelector((state: RootState) => state.nodesReducer.filter(node => node?.id === activeNodeId)[0]?.description);

    return(
        <div>
            <h1>
                {title}
            </h1>
            <p>
                {description}
            </p>
        </div>
    );
}

export default CardPreview;