import React, {useEffect} from "react";
import {Canvas} from "@react-three/fiber";



import {Node} from '../../components';
import {useDispatch, useSelector} from 'react-redux';


function Graph() {

    const nodes = useSelector(state => state.nodesReducer)
    const dispatch = useDispatch();

    const setActiveNode = (nodeId) => {
        dispatch({
            type: 'SET_ACTIVE_NODE', payload: nodeId
        })
    }

    useEffect(() => {
        document.addEventListener('addnewnode', (e) => {
            dispatch({type: 'ADD_NODE', payload: e.detail})
        })
    }, [])

    return (
        <>
            {nodes.map((item, index) =>
                <Node
                    onClick={() => setActiveNode(item.id)}
                    nodeId={item.id}
                    key={index}
                    position={item.position}
                    rotation={item.rotation}
                    title={item.title}
                    description={item.description}
                />
            )}
        </>
    );
}


export default function Scene() {
    return (
        <Canvas>
            <Graph/>
        </Canvas>
    );
}