import React, {FunctionComponent} from 'react';
import NodeHtml from '../node/NodeHtml';
import {Float, Html} from "@react-three/drei";
import {useThree} from "@react-three/fiber";
import {useGesture} from "@use-gesture/react";
import {useDispatch} from "react-redux";

interface NodePropsI {
    position: [number, number, number];
    title: string;
    description: string;
    rotation: number
    onClick: () => void;
    nodeId: number;
}

const Node: FunctionComponent<NodePropsI> = ({position, title, description, onClick, nodeId, rotation}) => {
    const dispatch = useDispatch();

    const setNodePosition = (nodeId: number, position: [number, number, number]) => {
        dispatch({
            type: 'SET_NODE_POSITION', payload: {nodeId: nodeId, position: position}
        })
    }

    const setNodeRotation = (nodeId: number, rotation: number) => {
        dispatch({
            type: 'SET_NODE_ROTATION', payload: {nodeId: nodeId, rotation: rotation}
        })
    }

    const {size, viewport} = useThree();

    // const aspect = size.width / viewport.width;
    const aspect = 20;


    const bind = useGesture({
            onDrag: (param) => {
                // Move node by X and Y axis with drag and drop
                if (param.offset[0] && param.offset[1] !== 0) {
                    setNodePosition(nodeId, [param.offset[0] / aspect, -param.offset[1] / aspect, position[2]]);
                }
            },
            onWheel: (state) => {
                // Zoom node by Z axis with vertical scroll
                if (!state.shiftKey) {
                    let deltaX = state.delta[1] / 5
                    if (position[2] < -5 && deltaX > 0) {
                        if (position[2] + deltaX > -5) {
                            setNodePosition(nodeId, [position[0], position[1], -5])
                        } else {
                            setNodePosition(nodeId, [position[0], position[1], position[2] + deltaX])
                        }
                    } else if (position[2] > -50 && deltaX < 0) {
                        if (position[2] + deltaX < -50) {
                            setNodePosition(nodeId, [position[0], position[1], -50])
                        } else {
                            setNodePosition(nodeId, [position[0], position[1], position[2] + deltaX])
                        }
                    }
                }
                // Rotate node by Y axis with horizontal scroll
                if (state.shiftKey) {
                    let deltaY = state.delta[0] / 40
                    // if (rotation + deltaY < 3 * Math.PI / 8) {
                    //     setNodeRotation(nodeId, rotation + deltaY);
                    // }
                    if (deltaY > 0 && rotation < 3 * Math.PI / 8) {
                        if (rotation + deltaY > 3 * Math.PI / 8) {
                            setNodeRotation(nodeId, 3 * Math.PI / 8);
                        } else {
                            setNodeRotation(nodeId, rotation + deltaY);

                        }
                    } else if (deltaY < 0 && rotation > -3 * Math.PI / 8) {
                        if (rotation + deltaY < -3 * Math.PI / 8) {
                            setNodeRotation(nodeId, -3 * Math.PI / 8);
                        } else {
                            setNodeRotation(nodeId, rotation + deltaY);

                        }
                    }
                }
            }
        },
        {
            scroll: {preventDefault: true}
        });


    return (
        <>
            {/*@ts-ignore*/}
            <Float
                speed={3}
                rotationIntensity={0.05}
                floatIntensity={0.05}
                floatingRange={[1, 1]}>
                {/*<OrbitControls/>*/}
                <ambientLight intensity={1}/>
                <group rotation={[0, rotation, 0]} position={position}>
                    <Html occlude transform>
                        <NodeHtml bind={bind} onClick={onClick}
                                  title={title}
                                  description={description}
                        />
                    </Html>
                </group>
            </Float>
        </>
    );
}

export default Node;