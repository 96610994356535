import * as React from "react";
import styles from './controls.module.css';
import addImg from '../../images/add.svg'
import markImg from '../../images/mark.svg';
import {FunctionComponent} from "react";

const Controls: FunctionComponent = () => {

    const addHandler = () => {
        let randomId = Math.floor(Math.random() * 1000)
        document.dispatchEvent(new CustomEvent('addnewnode', {
                detail:
                   {
                       id: randomId,
                       title: 'Title ' + randomId,
                       description: "Another one node",
                       position: [0, 0, -15],
                       rotation: 0,
                       child: [],
                       parent: null
                   }

            })
        )
    };

    return (
        <div className={styles.wrapper}>
            <button onClick={() =>  addHandler()}>
                <img src={addImg} alt="add"/>
            </button>
            <button onClick={() => addHandler()}>
                <img src={markImg} alt="mark"/>
            </button>
        </div>
    );
}

export default Controls;