enum nodesActionTypes {
    ADD_NODE = "ADD_NODE",
    SET_NODE_POSITION = "SET_NODE_POSITION",
    SET_NODE_ROTATION = "SET_NODE_ROTATION"
}

interface nodeI {
    id: number;
    title: string;
    description: string;
    position: [number, number, number];
    rotation: number;
    child: [];
    parent: number | null
}

interface addNodeI {
    type: nodesActionTypes.ADD_NODE;
    payload: nodeI
}

interface setNodePositionI {
    type: nodesActionTypes.SET_NODE_POSITION;
    payload: {
        nodeId: number,
        position: [number, number, number];
    }
}

interface setNodeRotationI {
    type: nodesActionTypes.SET_NODE_ROTATION;
    payload: {
        nodeId: number;
        rotation: number;
    };
}

type actionI = addNodeI | setNodePositionI | setNodeRotationI;


const defaultState: Array<nodeI> = [
    {
        id: 1,
        title: 'Another Title',
        description: "Description",
        position: [0, 0, -15],
        rotation: 0,
        child: [],
        parent: null
    }
]


export const nodesReducer = (state = defaultState, action: actionI) => {
    switch (action.type) {
        case nodesActionTypes.ADD_NODE:
            return [
                ...state,
                action.payload,
            ]
        case nodesActionTypes.SET_NODE_POSITION:
            return [
                ...state.map((node) => {
                    if (node.id === action.payload.nodeId) {
                        return {
                            ...node, position: action.payload.position
                        }
                    } else {
                        return {
                            ...node
                        }
                    }
                })
            ]
        case nodesActionTypes.SET_NODE_ROTATION:
            return [
                ...state.map((node) => {
                    if (node.id === action.payload.nodeId) {
                        return {
                            ...node, rotation: action.payload.rotation
                        }
                    } else {
                        return {
                            ...node
                        }
                    }
                })
            ]

        default:
            return state
    }
}